import React, { useEffect } from "react";
import { useState } from "react";
import "../App.css";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import icon from "../assets/dory-icon.png";
import phil from "../../src/external_node_modules/philippine-location-json-for-geer";
import renderInput from "../components/utilities/inputForms";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axios";
import moment from "moment";
import {
  Card,
  Typography,
  Form,
  notification,
  Divider,
  Row,
  Col,
  Checkbox,
  ConfigProvider,
  Button,
  Switch,
  Radio,
  Alert,
} from "antd";
import * as yup from "yup";

const { Title } = Typography;

const getUniqueOptions = (options, key) => {
  const seen = new Set();
  return options.filter((item) => {
    const keyValue = item[key];
    return seen.has(keyValue) ? false : seen.add(keyValue);
  });
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function RiderSignUp() {
  const query = useQuery();
  const navigate = useNavigate();
  const referralCode = query.get("ref");

  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [acceptedTermsAndCondition, setAcceptedTermsAndCondition] =
    useState(false);
  const [radioValue, setRadioValue] = useState("food");
  const [alreadyAccount, setAlreadyAccount] = useState(false);
  const [istwentyFourHrs, setIsTwentyFourHrs] = useState(false);

  const uniqueOptions = getUniqueOptions(phil.provinces, "prov_code");

  const schema = yup.object().shape({
    first_name: yup.string().required("first name is required"),
    last_name: yup.string().required("last name is required"),
    contact_number: yup
      .string()
      .required("Contact number is required")
      .matches(/^\d{11}$/, "Contact number must be exactly 11 digits")
      .min(11, "Contact number must be exactly 11 digits")
      .max(11, "Contact number must be exactly 11 digits"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    username: yup
      .string()
      .required("Username is required")
      .matches(/^\S*$/, "Username cannot contain spaces"),
    password: yup
      .string()
      .required("Password is required")
      .matches(/^\S*$/, "Password cannot contain spaces"),
    merchant_name: yup.string().required("Merchant name is required"),
    province: yup.string().required("Province is required"),
    city: yup.string().required("City is required"),
    barangay: yup.string().required("Barangay is required"),
    // landmark: yup.string().required("Landmark is required"),
    timeOpen: istwentyFourHrs
      ? ""
      : yup.string().required("Opening time is required"),
    timeClose: istwentyFourHrs
      ? ""
      : yup.string().required("Closing time is required"),
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      contact_number: "",
      email: "",
      username: "",
      password: "",
      merchant_name: "",
      province: "",
      city: "",
      barangay: "",
      landmark: "",
      timeOpen: "",
      timeClose: "",
    },
  });

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    if (!referralCode) {
      navigate("/404");
    }
  }, [referralCode, navigate]);

  const onChange = (checked) => {
    setIsTwentyFourHrs(!istwentyFourHrs);
  };

  const onChangeRadio = (e) => {
    setRadioValue(e.target.value);
  };
  const onChangeAlreadyAccount = (e) => {
    setAlreadyAccount(e.target.value);
  };

  const onError = (errors) => {
    notification.error({
      message: "Form Submission Error",
      description: "Please check the form for errors and try again.",
    });
    console.log("Form Errors:", errors);
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const parsedTimeOpen = moment(values.timeOpen, "HH:mm");
      let formattedTimeOpen = parsedTimeOpen.format("HH:mm:ss");

      const parsedTimeClose = moment(values.timeClose, "HH:mm");
      let formattedTimeClose = parsedTimeClose.format("HH:mm:ss");

      if (istwentyFourHrs) {
        formattedTimeOpen = "00:00:00";
        formattedTimeClose = "24:00:00";
      }

      // const province = phil.provinces.filter(
      //   (province) => province.prov_code === values.province
      // )[0];
      // const city = phil.city_mun.filter(
      //   (city) => city.mun_code === values.city
      // )[0].name;
      const barangay = phil.barangays.filter(
        (barangay) => barangay.brgy_code === values.barangay
      )[0].name;

      const finalData = {
        merchantName: values.merchant_name,
        firstName: values.first_name,
        lastName: values.last_name,
        contactNumber: values.contact_number,
        emailAddress: values.email,
        userName: values.username,
        password: values.password,
        // province: province.name,
        // city: city,
        province: values.province,
        city: values.city,
        barangay: barangay,
        landmark: values.landmark,
        timeOpen: formattedTimeOpen,
        timeClose: formattedTimeClose,
        referralCode,
        isNonFood: radioValue !== "food",
        subscriptionCode: values.coupon_code ? values.coupon_code : null,
        alreadyHasAccount: alreadyAccount,
        platform: "merchant",
      };

      const { data } = await axiosInstance().post(
        "/api/local-merchants",
        finalData
      );

      if (data) {
        navigate("/otp", {
          state: {
            otpSuccessLink: data.paymentLink,
            contact_number: values.contact_number,
            platform: "merchant",
            token: data.token,
          },
        });

        setLoading(false);

        // window.location.href = data.paymentLink;
      }
    } catch (error) {
      // console.log(">>>> error.response.data:", error.response.data);
      // if (error.response.data.userAlreadyExist) {
      openNotification("ERROR", error.response.data.message);
      // }

      // if (error.response.data.invalidReferral) {
      //   openNotification("ERROR", error.response.data.message);
      // }

      setLoading(false);
    }
  };

  // test
  const fetchProvincesList = async () => {
    try {
      const { data } = await axiosInstance().get(
        "/api/rider/serviceable-province-list"
      );

      if (data) {
        setProvinces(data.serviceable_provinces);
      }
    } catch (error) {
      console.log(">>> error fetching serviceable Provinces");
    }
  };

  useEffect(() => {
    fetchProvincesList();
  }, []);

  const fetchCitiesList = async (province) => {
    try {
      const { data } = await axiosInstance().get(
        `/api/rider/serviceable-cities-list/${province}`
      );

      setCities(data.serviceable_cities);
    } catch (error) {
      console.log(">>> error fetching serviceable Cities");
    }
  };

  const fetchBarangayList = async () => {
    try {
      const selectedCity = watch("city");
      const selectedProvince = watch("province");

      const { data } = await axiosInstance().get(
        `/api/rider/serviceable-barangay-list/${selectedProvince}/${selectedCity}`
      );

      setBarangays(data.barangayList);
    } catch (error) {
      console.log(">>> error fetching serviceable Cities");
    }
  };
  // test

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    fetchCitiesList(prov_code);
    // setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    fetchBarangayList();
    // setBarangays(phil.getBarangayByMun(mun_code));
  };

  const onCheck = (e) => {
    setAcceptedTermsAndCondition(!acceptedTermsAndCondition);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 600,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={icon} alt="" width={120} />
          </div>

          <Title level={2} style={{ margin: 0, textAlign: "center" }}>
            Merchant Application form
          </Title>
          {/* <Text type="secondary" style={{ fontSize: 16 }}>
            Enter your personal details
          </Text> */}
          <div style={{ marginTop: 20 }}>
            <Form
              layout="vertical"
              initialValues={{
                requiredMarkValue: true,
              }}
              requiredMark={true}
              onFinish={handleSubmit(onSubmit, onError)}
              encType="multipart/form-data"
            >
              <>
                <Divider orientation="left">Personal Information</Divider>
                <Row gutter={[32]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "First name",
                        name: "first_name",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Last name",
                        name: "last_name",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Divider orientation="left">Contact Information</Divider>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Contact Number",
                        name: "contact_number",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Email",
                        name: "email",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Divider orientation="left">Business Details</Divider>
                {/*  */}
                <>
                  <Alert
                    message="If you can't find your address, we're not in your area yet. We're growing fast, so check back soon!"
                    type="info"
                    showIcon
                  />
                  <br />
                </>
                {/* merchant name */}
                <Row gutter={[32]}>
                  <Col className="gutter-row" span={24} lg={24}>
                    {renderInput(
                      {
                        label: "Store name",
                        name: "merchant_name",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                </Row>
                {/*  */}
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Province",
                        name: "province",
                        errors: errors,
                        required: "true",
                        type: "select",
                        // options: uniqueOptions,
                        options: provinces,
                        // options: phil.provinces,
                        // valueKey: "prov_code",
                        valueKey: "province",
                        valueText: "province",
                        onChangeOutside: onChangeProvince,
                      },
                      control
                    )}
                  </Col>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "City/Municipality",
                        name: "city",
                        errors: errors,
                        required: "true",
                        type: "select",
                        options: cities,
                        valueKey: "name",
                        valueText: "name",
                        onChangeOutside: onChangeCity,
                      },
                      control
                    )}
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Barangay",
                        name: "barangay",
                        errors: errors,
                        required: "true",
                        type: "select",
                        options: barangays,
                        valueKey: "brgy_code",
                        valueText: "name",
                      },
                      control
                    )}
                  </Col>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Landmark",
                        name: "landmark",
                        errors: errors,
                        required: false,
                      },
                      control
                    )}
                  </Col>
                </Row>
                {/* test */}
                <Row gutter={[16, 16]}>
                  <Col span={10}>
                    {renderInput(
                      {
                        type: "time",
                        label: "Time Open",
                        name: "timeOpen",
                        errors: errors,
                        required: "false",
                        disabled: istwentyFourHrs,
                      },
                      control
                    )}
                  </Col>
                  <Col span={10}>
                    {renderInput(
                      {
                        type: "time",
                        label: "Time Close",
                        name: "timeClose",
                        errors: errors,
                        required: "false",
                        disabled: istwentyFourHrs,
                      },
                      control
                    )}
                  </Col>
                  <Col span={4}>
                    <div style={{ paddingTop: 35 }}>
                      <Switch
                        checked={istwentyFourHrs}
                        onChange={onChange}
                        checkedChildren="24hrs"
                        unCheckedChildren="24hrs?"
                        defaultChecked={istwentyFourHrs}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={[32]}>
                  <Col className="gutter-row" span={24} lg={24}>
                    <Form.Item
                      label="Is your business focused on food or non-food?"
                      required
                    >
                      <Radio.Group value={radioValue} onChange={onChangeRadio}>
                        <Radio value="food">Food</Radio>
                        <Radio value="non-food">Non-Food</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {/* test 
                <Row gutter={[32]}>
                  <Col className="gutter-row" span={24} lg={24}>
                    <Form.Item
                      label="Do you already have a merchant account?"
                      required
                    >
                      <Radio.Group
                        value={alreadyAccount}
                        onChange={onChangeAlreadyAccount}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>*/}
                {/* test */}
                {/* test */}
                <Divider orientation="left">
                  {alreadyAccount
                    ? "Please create another username."
                    : "Create a new account."}
                </Divider>
                <Row gutter={[16, 16]}>
                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Username",
                        name: "username",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>

                  <Col className="gutter-row" span={24} lg={12}>
                    {renderInput(
                      {
                        label: "Password",
                        name: "password",
                        errors: errors,
                        required: "true",
                        type: "password",
                      },
                      control
                    )}
                  </Col>
                </Row>

                <Divider orientation="left">Coupon</Divider>
                <Row gutter={[32]}>
                  <Col className="gutter-row" span={24} lg={24}>
                    {renderInput(
                      {
                        label: "Coupon code",
                        name: "coupon_code",
                        errors: errors,
                        required: false,
                        type: "text",
                      },
                      control
                    )}
                  </Col>
                </Row>

                <Checkbox onChange={onCheck} style={{ marginBottom: 16 }}>
                  {`I have read and agree to the `}
                  <a
                    href="/Merchant Agreement.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </a>
                </Checkbox>
                <div className="text-right mt-5" style={{ textAlign: "right" }}>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          defaultBg: "#00BF63",
                          defaultColor: "#FFF",
                        },
                      },
                    }}
                  >
                    <Button
                      block={true}
                      htmlType="submit"
                      type="default "
                      loading={loading}
                      disabled={!acceptedTermsAndCondition || loading}
                    >
                      Create Account
                    </Button>
                  </ConfigProvider>
                </div>
                {/*  */}
              </>
            </Form>
          </div>
        </Card>
      </div>
    </>
  );
}

export default RiderSignUp;
